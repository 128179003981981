export const ArticleContent = ({
  article,
  heading = "h3",
  showTag = true,
  showExcerpt = true,
  showTags = false,
  showDateline = true
}) => {
  const dateLine = [
    !article.meta.dateline?.showModified
      ? article.meta.dateline.modified
      : article.meta.dateline.created,
    article.dateline.readTime > 0
      ? article.dateline.readTime + " min att läsa"
      : false
  ]

  const HeadingTag = heading

  return (
    <>
      {showTag && article.tag?.label && (
        <span className="tag">{article.tag.label}</span>
      )}
      <div className="heading">
        <HeadingTag>{article.title}</HeadingTag>
      </div>
      {showExcerpt && <p>{article.lead}</p>}
      {showDateline && (
        <span className="dateline">{dateLine.filter(Boolean).join(" • ")}</span>
      )}
      {showTags && (
        <ul className="terms">
          {article.terms.length > 0 &&
            article.terms.map((term, index) => (
              <li className="term" key={term?.label + index}>
                {term.label}
              </li>
            ))}
        </ul>
      )}
    </>
  )
}
